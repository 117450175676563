import React from "react"
import { withPrefix } from "gatsby"
import { CLOUDINARY_URL } from "../../constants"

import BorderWindow from "../borderWindow"

import NavigationCaratIcon from "../../images/navigation_arrow--carat.svg"
import "./vision.scss"

const Slide = ({
  index,
  visionSlideText,
  visionSlideImage,
  slidesCount,
  mobile,
}) => (
  <>
    {index === slidesCount && !mobile && (
      <BorderWindow className="vision__border vision__border--epilogue animate">
        <BorderWindow.Border
          position="left"
          direction="clockwise"
          isLarge
          top
          bottom
          left
        />
        <BorderWindow.Border
          position="right"
          direction="clockwise"
          isLarge
          top
          right
          bottom
        />
      </BorderWindow>
    )}
    <div id={`slide-${mobile ? `mobile-` : ""}${index}`} className="slide">
      <div className="slide__content">
        {visionSlideText && visionSlideText.content && (
          <div
            className={`slide__content-${
              visionSlideImage.length > 0 ? "left" : "full"
            }`}
          >
            <div
              className={`slide__text${
                visionSlideImage.length > 0 ? "" : " slide__text--full"
              }`}
              dangerouslySetInnerHTML={{ __html: visionSlideText.content }}
            />
          </div>
        )}
        {visionSlideImage.length > 0 && (
          <div className="slide__content-right">
            <div className="slide__graphic">
              <picture>
                <img
                  src={`${CLOUDINARY_URL}/${visionSlideImage[0].filename}`}
                  alt={
                    visionSlideImage[0].imageCaption &&
                    visionSlideImage[0].imageCaption.content
                      ? visionSlideImage[0].imageCaption.contetn
                      : visionSlideImage[0].title
                  }
                />
              </picture>
            </div>
          </div>
        )}
      </div>
    </div>
  </>
)

class VisionSection extends React.Component {
  render() {
    const { heading, slides } = this.props
    return (
      <section id="vision" className="layout__section vision">
        <div className="vision__background">
          <img
            src={withPrefix("/images/homepage/vision_bg_art.gif")}
            alt="Top-down view of a city planner drawing of buildings and park"
          />
        </div>

        {slides && slides.length > 0 && (
          <div className="fixed-nav-wrapper">
            <nav className="layout__fixed-nav layout__fixed-nav--left slider__numbers">
              <a
                href="#slide-1"
                className="slider__number slide-counter-1 slider__number--active"
                data-target="9800"
              >
                01
              </a>
              <a
                href="#slide-2"
                className="slider__number slide-counter-2"
                data-target="10900"
              >
                02
              </a>
              <a
                href="#slide-3"
                className="slider__number slide-counter-3"
                data-target="12000"
              >
                03
              </a>
              <a
                href="#slide-4"
                className="slider__number slide-counter-4"
                data-target="14200"
              >
                04
              </a>
            </nav>
          </div>
        )}

        {heading && (
          <div className="fixed-nav-wrapper hide-on--tablet">
            <nav className="layout__fixed-nav layout__fixed-nav--right layout__fixed-nav--section-nav">
              <h2>{heading}</h2>
            </nav>
          </div>
        )}

        <div className="sections slider hide-on--mobile" data-active-slide="1">
          <BorderWindow className="vision__border vision__border--initial">
            <BorderWindow.Border
              position="left"
              direction="counterclockwise"
              top
              right
              bottom
              left
            />
            <BorderWindow.Border
              position="right"
              direction="counterclockwise"
              top
              right
              bottom
              left
            />
          </BorderWindow>

          {heading && (
            <header className="vision__title heading hide-on--mobile">
              <h1>{heading}</h1>
            </header>
          )}

          {/* insert slides */}
          {slides.length > 0 &&
            slides.map(({ id, visionSlideImage, visionSlideText }, i) => {
              return (
                <Slide
                  key={`slide-${id}`}
                  index={i + 1}
                  visionSlideImage={visionSlideImage}
                  visionSlideText={visionSlideText}
                  slidesCount={slides.length}
                />
              )
            })}
        </div>

        {/* MOBILE VERSION */}
        <div
          className="sections slider slideshow hide-on--tablet"
          data-active-slide="1"
        >
          <div className="slider__wrapper">
            {/* insert slides */}
            {slides.length > 0 &&
              slides.map(({ id, visionSlideImage, visionSlideText }, i) => {
                return (
                  <Slide
                    key={`slide-mobile-${id}`}
                    index={i + 1}
                    visionSlideImage={visionSlideImage}
                    visionSlideText={visionSlideText}
                    slidesCount={slides.length}
                    mobile
                  />
                )
              })}
          </div>
          <nav className="slideshow-nav">
            <a href="#" className="prev">
              <NavigationCaratIcon />
            </a>
            <div className="dots">
              <a href="#slide-mobile-1" className="dot">
                01
              </a>
              <a href="#slide-mobile-2" className="dot">
                02
              </a>
              <a href="#slide-mobile-3" className="dot">
                03
              </a>
              <a href="#slide-mobile-4" className="dot">
                04
              </a>
            </div>
            <a href="#" className="next">
              <NavigationCaratIcon />
            </a>
          </nav>
        </div>
      </section>
    )
  }
}

export default VisionSection
