import React from "react"
import { CLOUDINARY_URL } from "../../constants"

import BorderWindow from "../borderWindow"

import AccordionTrigger from "../../images/accordionTrigger.svg"
import "./capabilities.scss"

const CapabilityMeta = [
  {
    border: (
      <BorderWindow.Border
        position="left"
        direction="counterclockwise"
        left
        bottom
      />
    ),
    className: "border-left-bottom",
  },
  {
    border: (
      <BorderWindow.Border
        position="right"
        direction="clockwise"
        right
        bottom
      />
    ),
    className: "capability--align-right border-right-bottom",
  },
  {
    border: (
      <BorderWindow.Border position="left" direction="counterclockwise" left />
    ),
    className: "border-left",
  },
]

const Capability = ({ id, index, title, subtitle, description, list }) => (
  <div
    key={`capability-${id}`}
    className={`capability capability--${title
      .toLowerCase()
      .replace(" ", "-")} ${
      CapabilityMeta[index].className
    } animate accordion__group`}
  >
    {CapabilityMeta[index].border}
    <div className="capability__wrapper">
      <header>
        <button className="accordion__trigger hide-on--tablet">
          <AccordionTrigger />
        </button>
        <h1>{title}</h1>
        <h2>{subtitle}</h2>
      </header>
      <div className="capability__content">
        <div
          className="capability__content-description"
          dangerouslySetInnerHTML={{ __html: description.content }}
        />
        <div
          className="capability__sub-list font--small"
          dangerouslySetInnerHTML={{ __html: list.content }}
        />
      </div>
    </div>
  </div>
)

const Capabilities = ({ heading, capabilitiesHeader, capabilities }) => (
  <section id="capabilities" className="layout__section capabilities">
    <div className="fixed-nav-wrapper hide-on--tablet">
      <nav className="layout__fixed-nav layout__fixed-nav--right layout__fixed-nav--section-nav">
        <h2>{heading}</h2>
      </nav>
    </div>

    {capabilitiesHeader.length > 0 &&
      capabilitiesHeader.map(
        ({
          id,
          capabilitiesHeaderHeading,
          capabilitiesHeaderSubHeading,
          capabilitiesHeaderHeroImage,
        }) => (
          <div
            key={`capabilitiesHeader-${id}`}
            className="layout__section layout__full capability__heading"
          >
            {capabilitiesHeaderHeroImage.length > 0 && (
              <picture>
                <source
                  srcSet={`${CLOUDINARY_URL}/${
                    capabilitiesHeaderHeroImage[1].filename
                  }`}
                  media="(max-width: 680px)"
                />
                <img
                  src={`${CLOUDINARY_URL}/${
                    capabilitiesHeaderHeroImage[0].filename
                  }`}
                  alt={capabilitiesHeaderHeroImage[0].title}
                />
              </picture>
            )}

            <div className="border__window border__window--fixed border__window--solid" />

            <div className="layout__main">
              <div className="layout__inner layout__inner--full-width">
                <header className="heading">
                  {capabilitiesHeaderHeading && (
                    <h1>{capabilitiesHeaderHeading}</h1>
                  )}
                  {capabilitiesHeaderSubHeading && (
                    <div className="heading__subtext">
                      <h2>{capabilitiesHeaderSubHeading}</h2>
                    </div>
                  )}
                </header>
              </div>
            </div>
          </div>
        )
      )}

    <div className="layout__main accordion">
      {capabilities.length > 0 &&
        capabilities.map(
          (
            {
              id,
              capabilityTitle,
              capabilitySubtitle,
              capabilityDescription,
              capabilityList,
            },
            i
          ) => (
            <Capability
              index={i}
              id={id}
              key={`capability-${i + 1}`}
              title={capabilityTitle}
              subtitle={capabilitySubtitle}
              description={capabilityDescription}
              list={capabilityList}
            />
          )
        )}
    </div>
  </section>
)

export default Capabilities
