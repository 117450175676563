import React from "react"
import { CLOUDINARY_URL } from "../../constants"

import InstagramFeed from "../instagram"

import NavigationArrow from "../../images/navigation_arrow.svg"
import "./about.scss"

const About = ({
  aboutUsSectionHeading,
  visionSectionHeading,
  capabilitySectionHeading,
  aboutUs,
}) => (
  <section id="about" className="about layout__section background--accent">
    <div className="fixed-nav-wrapper">
      <nav className="layout__fixed-nav layout__fixed-nav--right layout__fixed-nav--section-nav">
        <a href="#" className="slider__trigger slider__trigger--prev">
          <NavigationArrow />
        </a>
        <div className="hide-on--mobile">
          <div className="heading-title">
            <h2 className="heading-title--vision">{visionSectionHeading}</h2>
            <h2 className="heading-title--capabilities">
              {capabilitySectionHeading}
            </h2>
            <h2 className="heading-title--about">{aboutUsSectionHeading}</h2>
          </div>
        </div>
        <div className="hide-on--tablet">
          <h2>{aboutUsSectionHeading}</h2>
        </div>
        <a href="#" className="slider__trigger slider__trigger--next">
          <NavigationArrow />
        </a>
      </nav>
    </div>

    {aboutUs &&
      aboutUs.length > 0 &&
      aboutUs.map(
        ({
          id,
          aboutUsContentBody,
          aboutUsContentHeading,
          aboutUsContentImage,
        }) => (
          <div key={`about-us-content-${id}`}>
            <div className="layout__full about__graphic">
              {aboutUsContentImage.length > 0 && (
                <picture>
                  {/* <source
                srcSet="/src/assets/images/about_heading--sm.jpg 1x"
                media="(max-width: 680px)"
              /> */}
                  <img
                    src={`${CLOUDINARY_URL}/${aboutUsContentImage[0].filename}`}
                    alt={aboutUsContentImage[0].title}
                  />
                </picture>
              )}
            </div>

            <div className="layout__main">
              <div className="layout__inner layout__inner--full-width">
                <header className="about__heading heading">
                  <h1>{aboutUsContentHeading}</h1>
                </header>
              </div>
              <div className="layout__inner layout__inner--full-width">
                <div
                  className="about__description"
                  dangerouslySetInnerHTML={{
                    __html: aboutUsContentBody.content,
                  }}
                />
                <div className="about__other">
                  <InstagramFeed />
                </div>
              </div>
            </div>
          </div>
        )
      )}
  </section>
)

export default About
