import React from "react"

import "./borderWindow.scss"

class BorderWindow extends React.Component {
  render() {
    const { className, children, position, direction } = this.props
    return (
      <div className="border__window">
        <div className={className}>
          {!children ? (
            <BorderWindow.Border
              position={position}
              direction={direction}
              top
              right
              bottom
              left
            />
          ) : (
            children
          )}
        </div>
      </div>
    )
  }
}

BorderWindow.Border = ({
  position,
  direction,
  top,
  right,
  bottom,
  left,
  isLarge,
}) => {
  const positionClass = position ? `border__frame--${position}` : null
  const directionClass = direction ? `direction--${direction}` : null
  const isLargeClass = isLarge ? "border__line--large" : null
  return (
    <div className={`border__frame ${positionClass} ${directionClass}`}>
      {top && (
        <div className={`border__line ${isLargeClass} border__line--top`} />
      )}
      {right && (
        <div className={`border__line ${isLargeClass} border__line--right`} />
      )}
      {bottom && (
        <div className={`border__line ${isLargeClass} border__line--bottom`} />
      )}
      {left && (
        <div className={`border__line ${isLargeClass} border__line--left`} />
      )}
    </div>
  )
}

export default BorderWindow
