import React from "react"

import Sun from "../../images/sun.svg"
import Moon from "../../images/moon.svg"
import CloudLeft from "../../images/cloudLeft.svg"
import CloudRight from "../../images/cloudRight.svg"
import CityscapeLayer1 from "../../images/cityscapeLayer1.svg"
import CityscapeLayer2 from "../../images/cityscapeLayer2.svg"
import CityscapeLayer3 from "../../images/cityscapeLayer3.svg"
import CityscapeLayer4 from "../../images/cityscapeLayer4.svg"
import CityscapeLayer5 from "../../images/cityscapeLayer5.svg"
import CityscapeLayer6 from "../../images/cityscapeLayer6.svg"
import CityscapeLayer7 from "../../images/cityscapeLayer7.svg"
import CityscapeLayer8 from "../../images/cityscapeLayer8.svg"

import "./cityscape.scss"

const cityscape = [
  {
    layerClass: "parallax__layer",
    layerImage: CityscapeLayer1,
    layerInBottomGroup: false,
  },
  {
    layerClass: "parallax__layer",
    layerImage: CityscapeLayer2,
    layerInBottomGroup: false,
  },
  {
    layerClass: "parallax__layer",
    layerImage: CityscapeLayer3,
    layerInBottomGroup: false,
  },
  {
    layerClass: "parallax__layer",
    layerImage: CityscapeLayer4,
    layerInBottomGroup: false,
  },
  {
    layerClass: "parallax__layer",
    layerImage: CityscapeLayer5,
    layerInBottomGroup: false,
  },
  {
    layerClass: null,
    layerImage: CityscapeLayer6,
    layerInBottomGroup: true,
  },
  {
    layerClass: null,
    layerImage: CityscapeLayer7,
    layerInBottomGroup: true,
  },
  {
    layerClass: null,
    layerImage: CityscapeLayer8,
    layerInBottomGroup: true,
  },
]

const Layer = ({ index, layerImage, className }) => {
  const LayerImage = layerImage
  return (
    <div id={`parallax-${index}`} className={className}>
      <LayerImage />
    </div>
  )
}

const Cityscape = ({ className }) => {
  // Group cityscape layers
  const cityscapeTopLayers = []
  const cityscapeBottomLayers = []

  cityscape.map((layer, i) => {
    layer.index = (i + 1).toString()
    if (layer.layerInBottomGroup) {
      cityscapeBottomLayers.push(
        <Layer
          key={`cityscapeLayer-${layer.index}`}
          index={layer.index}
          layerImage={layer.layerImage}
          className={layer.layerClass}
        />
      )
    } else {
      cityscapeTopLayers.push(
        <Layer
          key={`cityscapeLayer-${layer.index}`}
          index={layer.index}
          layerImage={layer.layerImage}
          className={layer.layerClass}
        />
      )
    }
    return layer
  })

  return (
    <>
      <div className="parallax hide-on--mobile">
        <div className="parallax-wrapper">
          <div id="parallax-0" className="parallax__layer">
            <div id="theSun" className="parallax__img">
              <Sun />
            </div>

            <div id="theMoon" className="parallax__img parallax__layer00">
              <Moon />
            </div>

            <div
              id="cloudLeft"
              className="parallax__img parallax__layer01 cloudLeftAnim"
            >
              <CloudLeft />
            </div>

            <div
              id="cloudRight"
              className="parallax__img parallax__layer01 cloudRightAnim"
            >
              <CloudRight />
            </div>
          </div>

          {cityscapeTopLayers}
        </div>
      </div>

      <div className="bottom-buildings hide-on--mobile">
        {cityscapeBottomLayers}
      </div>

      {/* Insert placeholder images */}
    </>
  )
}

export default Cityscape
