import React from "react"
import { graphql, withPrefix } from "gatsby"
import { Helmet } from "react-helmet"
import { CLOUDINARY_URL } from "../constants"

import Layout from "../components/layout/siteLayout"
import SEO from "../components/layout/seo"
import Cityscape from "../components/homepage/cityscape"
import Vision from "../components/homepage/vision"
import Capabilities from "../components/homepage/capabilities"
import About from "../components/homepage/about"

import "./index.scss"

import Logo from "../images/amplified-logo.svg"
import WhiteCaratIcon from "../images/navigation_arrow--carat-white.svg"

// const Outro = ({ content }) => (
//   <div className="intro__bottom">
//     <div className="intro__bottom--wrapper">
//       <div className="bottom-parallax-text">
//         <div className="layout__main">
//           <div className="layout__inner">
//             <header
//               className="heading heading--hero"
//               dangerouslySetInnerHTML={{ __html: content }}
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// )

const IndexPage = props => {
  const { data, location } = props
  const {
    introText1,
    introText2,
    introText3,
    outroText,
    mobilePlaceholderImage,
    // tabletPlaceholderImage,
    visionSectionHeading,
    visionSlides,
    capabilitySectionHeading,
    capabilitiesHeader,
    capabilities,
    aboutUsSectionHeading,
    aboutUs,
  } = data.craft.entry
  return (
    <Layout location={location}>
      <Helmet bodyAttributes={{ class: "home" }}>
        <script src={withPrefix("/scripts/homepage.js")} />
      </Helmet>
      <SEO location={location} />

      <section id="intro" className="layout__section intro background_default">
        <div className="mission-clouds" />
        <div className="mission-continued-clouds" />
        <div className="mission-final-clouds" />

        <div className="intro__top">
          <div className="opacity-text">
            <div className="logo">
              <Logo />
              <button
                type="button"
                className="logo__more logo__more--desktop hide-on--mobile"
              >
                <WhiteCaratIcon />
              </button>
              <button
                type="button"
                className="logo__more logo__more--mobile hide-on--tablet"
              >
                <WhiteCaratIcon />
              </button>
            </div>

            <div className="mission">
              <div className="layout__main">
                <div className="layout__inner">
                  <header
                    className="heading heading--hero"
                    dangerouslySetInnerHTML={{ __html: introText1.content }}
                  />
                </div>
              </div>
            </div>

            <div className="mission-continued">
              <div className="layout__main">
                <div className="layout__inner">
                  <header
                    className="heading heading--hero"
                    dangerouslySetInnerHTML={{ __html: introText2.content }}
                  />
                </div>
              </div>
            </div>

            <div className="mission-final">
              <div className="layout__main">
                <div className="layout__inner">
                  <header
                    className="heading heading--hero"
                    dangerouslySetInnerHTML={{ __html: introText3.content }}
                  />
                </div>
              </div>
            </div>
          </div>

          <Cityscape />

          {mobilePlaceholderImage.length > 0 && (
            <img
              className="parallax--mobile-placeholder hide-on--tablet"
              src={`${CLOUDINARY_URL}/${mobilePlaceholderImage[0].filename}`}
              alt={
                mobilePlaceholderImage[0].imageCaption &&
                mobilePlaceholderImage[0].imageCaption.content
                  ? mobilePlaceholderImage[0].imageCaption.content
                  : mobilePlaceholderImage[0].title
              }
            />
          )}
        </div>

        {/* Had to include as a separate component because Gatsby was removing the nested divs */}
        {/* <Outro content={outroText.content} /> */}
        <div className="intro__bottom">
          <div className="intro__bottom--wrapper">
            <div className="bottom-parallax-text">
              <div className="layout__main">
                <div className="layout__inner">
                  <header
                    className="heading heading--hero"
                    dangerouslySetInnerHTML={{ __html: outroText.content }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Vision heading={visionSectionHeading} slides={visionSlides} />

      <Capabilities
        heading={capabilitySectionHeading}
        capabilitiesHeader={capabilitiesHeader}
        capabilities={capabilities}
      />

      <About
        aboutUsSectionHeading={aboutUsSectionHeading}
        capabilitySectionHeading={capabilitySectionHeading}
        visionSectionHeading={visionSectionHeading}
        aboutUs={aboutUs}
      />
    </Layout>
  )
}

export const getHomepageContentQuery = graphql`
  query getHomepageContentQuery {
    craft {
      entry(section: homepage) {
        ... on Craft_Homepage {
          # INTRO
          introText1 {
            content
          }
          introText2 {
            content
          }
          introText3 {
            content
          }
          outroText {
            content
          }
          mobilePlaceholderImage {
            ... on Craft_HomepageVolume {
              id
              filename
            }
          }
          tabletPlaceholderImage {
            ... on Craft_HomepageVolume {
              id
              filename
            }
          }
          # VISION
          visionSectionHeading
          visionSlides {
            ... on Craft_VisionSlidesVisionSlide {
              id
              visionSlideText {
                content
              }
              visionSlideImage {
                ... on Craft_CloudImagesVolume {
                  id
                  filename
                }
              }
            }
          }
          # CAPABILITIES
          capabilitySectionHeading
          capabilitiesHeader {
            __typename
            ... on Craft_CapabilitiesHeaderCapabilitiesHeader {
              id
              capabilitiesHeaderHeading
              capabilitiesHeaderSubHeading
              capabilitiesHeaderHeroImage {
                ... on Craft_HeroVolume {
                  id
                  filename
                  focalPoint {
                    x
                    y
                  }
                  height
                  title
                  width
                }
              }
            }
          }
          capabilities {
            __typename
            ... on Craft_CapabilitiesCapability {
              id
              capabilityTitle
              capabilitySubtitle
              capabilityList {
                content
              }
              capabilityDescription {
                content
              }
              capabilityImage {
                ... on Craft_CloudImagesVolume {
                  filename
                }
              }
            }
          }
          # ABOUT US
          aboutUsSectionHeading
          aboutUs {
            ... on Craft_AboutUsAboutUsContent {
              id
              aboutUsContentHeading
              aboutUsContentImage {
                ... on Craft_HeroVolume {
                  id
                  filename
                  focalPoint {
                    x
                    y
                  }
                  height
                  title
                  width
                }
              }
              aboutUsContentBody {
                content
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
