import React from "react"

import InstagramIcon from "../images/social-instagram.svg"

import "./instagram.scss"

const InstagramFeed = () => (
  <div className="instagram-wrapper">
    <div className="instagram-tile">
      <a href="https://www.instagram.com/amplifiedbydesign/">
        <iframe
          src="https://snapwidget.com/embed/473855"
          className="snapwidget-widget"
          allowtransparency="true"
          frameBorder="0"
          scrolling="no"
          style={{
            border: "none",
            overflow: "hidden",
            width: "100%",
            height: "100%",
          }}
          title="Instagram Feed"
        />
        <div className="instagram-hover">
          <InstagramIcon />
          <h3>@amplifiedbydesign</h3>
        </div>
      </a>
    </div>
  </div>
)

export default InstagramFeed
